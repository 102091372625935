export const isBrowser = () => typeof window !== "undefined";

export const getLocalePath = () => {
  let path = "";
  if (isBrowser()) {
    path = window.localStorage.getItem("alifa_localePath");
  }
  return path;
}

export const setLocalePath = (path) => {
  if (isBrowser()) {
    window.localStorage.setItem("alifa_localePath", path);
  }
}

export const getLanguagePath = () => {
  let path = "";
  if (isBrowser()) {
    path = window.localStorage.getItem("alifa_langPath");
  }
  return path;
};

export const setLanguagePath = (path) => {
  if (isBrowser()) {
    window.localStorage.setItem("alifa_langPath", path);
  }
};

export const getLanguageKey = () => {
  let key = "";
  if (isBrowser()) {
    key = window.localStorage.getItem("alifa_langKey");
  }
  return key;
};

export const setLanguageKey = (key) => {
  if (isBrowser()) {
    window.localStorage.setItem("alifa_langKey", key);
  }
};

export const getLanguageList = () => {
  if (isBrowser()) {
    JSON.parse(window.localStorage.getItem("alifa_langList"));
  }
};

export const setLanguageList = (langs) => {
  if (isBrowser()) {
    localStorage.setItem('testObject', JSON.stringify(langs));
  }
};

export const setPreferredLanguage = (lang) => {
  if (isBrowser()) {
    window.localStorage.setItem("alifa_userLang", lang);
  }
};

export const getPreferredLanguage = () => {
  if (isBrowser()) {
    return window.localStorage.getItem("alifa_userLang");
  }
};

export const hasPreferredLanguage = () => {
  if (isBrowser()) {
    return !!window.localStorage.getItem("alifa_userLang");
  }
}

export const getUserLanguage = () => {
  if (isBrowser()) {
    return (Array.isArray(window.navigator.languages) ? window.navigator.languages[0] :
      (window.navigator.userLanguage || window.navigator.language));
  }
};

