export const isBrowser = () => typeof window !== "undefined";

export const hasDocument = () => typeof document !== "undefined";

export const useDevServerUrl = (["localhost", "127.0.0.1"].includes((typeof window !== "undefined") && window.location.hostname));

export const getUser = () => {
  let userInfo = {};
  if (isBrowser()) {
      userInfo = window.localStorage.getItem("alifa_userInfo");
      if (userInfo) {
        return JSON.parse(userInfo);
      }
  }
  return userInfo;
};

export const setUser = (user) => {
  if (isBrowser()) {
    window.localStorage.setItem("alifa_userInfo", JSON.stringify(user));
  }
};

export const getAccessToken = () => {
  let token = "";
  if (isBrowser()) {
    token = window.localStorage.getItem("alifa_token");
  }
  return token;
};

export const setAccessToken = (token) => {
  if (isBrowser()) {
    window.localStorage.setItem("alifa_token", token);
  }
};

export const setRefreshToken = (token) => {
  if (isBrowser()) {
    window.localStorage.setItem("alifa_refreshToken", token);
  }
};

export const getRefreshToken = () => {
  let token = "";
  if (isBrowser()) {
    token = window.localStorage.getItem("alifa_refreshToken");
  }
  return token;
};

export const setExpiresIn = (expires) => {
  if (isBrowser()) {
    window.localStorage.setItem("alifa_expiresIn", expires);
  }
};

export const getExpiresIn = () => {
  let expires = 3600;
  if (isBrowser()) {
    expires = window.localStorage.getItem("alifa_expiresIn");
  }
  return expires;
};

export const setTimezoneAdjustment = (timezone) => {
  if (isBrowser()) {
    window.localStorage.setItem("alifa_timezone", timezone);
  }
};

export const getTimezoneAdjustment = () => {
  let timezone = 0;
  if (isBrowser()) {
    timezone = window.localStorage.getItem("alifa_timezone");
  }
  return timezone;
};

export const setTimezoneOffset = (offset) => {
  window.localStorage.setItem("alifa_tzOffset", offset);
};

export const getTimezoneOffset = () => {
  let timezone = 0;
  if (isBrowser()) {
    timezone = window.localStorage.getItem("alifa_tzOffset");
  }
  return timezone;
};

export const getUserRoles = () => {
  let roles = [];
  if (isBrowser()) {
    roles = window.localStorage.getItem("alifa_userRoles");
    if (roles) {
      roles = JSON.parse(roles);
    }
  }
  return roles;
};

export const setUserRoles = (roles) => {
  window.localStorage.setItem("alifa_userRoles", roles);
};

export const isLoggedIn = () => {
  const user = getUser();
  return user && !!user.username;
};

export const logout = callback => {
  setUser({});
  (typeof callback === "function") && callback();
};

